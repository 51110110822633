// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import signature from './plugins/signature'
import i18n from './i18n'
import DatetimePicker from 'vuetify-datetime-picker'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import axios from 'axios'
import VueAxios from 'vue-axios'

const bus = new Vue()

Vue.config.productionTip = false

Vue.use(DatetimePicker)

Vue.use(VueTelInputVuetify, {
  vuetify,
})

Vue.use(VueAxios, axios)

// Sets the default url used by all of this axios instance's requests
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.get.Accept = 'application/json'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  signature,
  render: h => h(App),
}).$mount('#app')

export default bus
