import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import { Role } from '@/constants/role'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: {
        path: '/member/register',
      },
    },
    {
      path: '/',
      component: () => import('@/views/Noauth'),
      children: [
        // Login
        {
          name: 'Login',
          path: 'login',
          meta: {
            requiresAuth: false,
            authorize: [],
          },
          component: () => import('@/views/pages/Login'),
        },
        // Logout
        {
          name: 'Logout',
          path: 'logout',
          meta: {
              requiresAuth: false,
              authorize: [],
          },
          component: () =>
            import('@/views/components/core/Logout'),
        },
        // RecoverPassword
        {
          name: 'RecoverPassword',
          path: 'recoverpassword',
          meta: {
              requiresAuth: false,
              authorize: [],
          },
          component: () =>
              import('@/views/pages/RecoverPassword'),
        },
        // Unauthorize
        {
          name: 'Unauthorized',
          path: 'unauthorized',
          meta: {
              requiresAuth: false,
              authorize: [],
          },
          component: () =>
              import('@/views/pages/Unauthorized'),
        },
        // Member Register
        {
          name: 'Member Register',
          path: 'member/register',
          component: () => import('@/views/member/Register'),
        },
        // Member Register With Token
        {
          name: 'Member Token Register',
          path: 'member/register/:token',
          component: () => import('@/views/member/Register'),
        },
        // Member Renewal (siguientes pagos después de haber pagado una primera vez)
        /* {
          name: 'Member Renewal',
          path: 'member/renewal',
          component: () => import('@/views/member/Renewal'),
        }, */
        // Member Resume
        {
          name: 'memberResume',
          path: 'member/resume/:token',
          component: () => import('@/views/member/MemberResume'),
        },
      ],
    },
    {
      path: '/admin',
      meta: {
        requiresAuth: true,
        authorize: [Role.Admin, Role.Reviewer],
      },
      component: () => import('@/views/admin/AdminLayout'),
      children: [
        // Dashboard
        {
          name: 'Dashboard administrador',
          path: 'dashboard',
          meta: {
            requiresAuth: true,
            authorize: [Role.Admin, Role.Reviewer],
          },
          component: () => import('@/views/admin/Dashboard'),
        },
        // Perfil
        {
          name: 'Perfil de usuario',
          path: 'perfil',
          meta: {
              requiresAuth: true,
              authorize: [Role.Admin],
          },
          component: () => import('@/views/admin/AdminProfile'),
        },
        // Usuarios
        {
          name: 'Usuarios',
          path: 'usuarios',
          meta: {
              requiresAuth: true,
              authorize: [Role.Admin],
          },
          component: () => import('@/views/admin/Usuarios'),
        },
        // Grupos
        {
          name: 'Grupos',
          path: 'grupos',
          meta: {
              requiresAuth: true,
              authorize: [Role.Admin, Role.Reviewer],
          },
          component: () => import('@/views/admin/Grupos'),
        },
        // Miembros
        {
          name: 'Miembros',
          path: 'miembros',
          meta: {
              requiresAuth: true,
              authorize: [Role.Admin, Role.Reviewer],
          },
          component: () => import('@/views/admin/Miembros'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const authorize = to.meta.authorize
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.authorized) {
          if (authorize.length && authorize.includes(localStorage.getItem('role'))) {
              next()
          } else {
              next('/unauthorized')
          }
      } else {
          next('/login')
      }
  } else {
      next()
  }
})

export default router
